import { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import xicon from "../../assets/x.png";
import Web3 from "web3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faWallet } from "@fortawesome/free-solid-svg-icons";
import { ACG_CONTRACT } from "../../constants/contracts";
import PageLoader from "../../components/PageLoader";

const RPC_URL = "https://mainnet.infura.io/v3/c02e258d757d47089f5621880c147dbd";
// const RPC_URL = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));
const nftContract = new web3.eth.Contract(
  ACG_CONTRACT.abi,
  ACG_CONTRACT.address
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    maxWidth: "100%",
  },
};

const Home = ({ mintItem, connect, killSession, connected, address, getAccountBalance }) => {
  const [remainCount, setRemainCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [price, setPrice] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [msgTitle, setMsgTitle] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [selected, setSelected] = useState(0);
  const [toggleBtn, setToggleBtn] = useState(false);
  const [stateOfNft, setStateOfNft] = useState(1); // for the arrows in first page
  const handleClose = () => setShow(false);
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMinted, setIsMinted] = useState(false);

  let interval = useRef();

  const handleShow = () => setShow(true);

  const countDown = (val) => {
    if (selected >= 1) {
      setSelected((prev) => prev - 1);
      setTotalPrice(0.00);
    }
  };

  const countUp = (val) => {
    if (selected < 10) {
      setSelected((prev) => prev + 1);
      setTotalPrice(0.00);
    }
  };

  const countNum = (val) => {
    setSelected(val);
    setTotalPrice(0.00);
};

  useEffect(async () => {
    await getInitialized();
  }, [address]);

  const getInitialized = async () => {
    setIsPageLoading(true);
    const minted_num = await nftContract.methods.totalSupply().call();
    const max_mum = await nftContract.methods.MAX_SUPPLY().call();
    setRemainCount(max_mum - minted_num);
    setMaxCount(max_mum);
    setBalance(await getAccountBalance());
    await getPrice();
    setIsPageLoading(false);
  };

  const getPrice = async () => {
    let res = await nftContract.methods.PRICE().call();
    const mintPrice = res.toString() / Math.pow(10, 18);
    setPrice(mintPrice);
    if ( mintPrice == 0.04 ) setStateOfNft(1);
    if ( mintPrice == 0.05 ) setStateOfNft(2);
    if ( mintPrice == 0.06 ) setStateOfNft(3);
    if ( mintPrice == 0.00 ) setStateOfNft(0);
  }

  const onMintItem = async (val) => {
    if (val < 1) {
      setMsgTitle("Sorry!");
      setMsgContent("Minimum NFT Amount Must Be Greater Than 1");
      handleShow();
    } else {
      setIsPageLoading(true);      
      let result = await mintItem(val);
      if (result > 0) {
        setIsMinted(true);
        await getInitialized();
        setBalance(await getAccountBalance());
        setIsPageLoading(false);
        setMsgTitle("Congratulations!");
        setMsgContent("Your Mint has been successfully!");
        handleShow();
      } else if (result == 0) {
        setIsPageLoading(false);
        setMsgTitle("Sorry!");
        setMsgContent("Public Sale is not active yet.");
        handleShow();
      } else {
        setIsPageLoading(false);
        setMsgTitle("Sorry!");
        setMsgContent("Your Mint failed.");
        handleShow();
      }
    }
  };

  const startTimer = () => {
    let end = new Date("12/19/2022 8:00 PM");
    interval = setInterval(() => {
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour * 24;
      let now = new Date();
      let nowUTC = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      );
      let distance = end - nowUTC;
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      if (distance < 0) {
        clearInterval(interval.current);
        return;
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div className="container-main">
      {isPageLoading && <PageLoader />}
      <header>
        <div className="flex desktop">
          <img src="/logo.png" alt="" />
          <button className="home-btn" onClick={() => window.open("https://www.alleycatgang.com/", "_blank")}>Home</button>
        </div>
        <div className="mobile back" onClick={() => window.open("https://www.alleycatgang.com/", "_blank")}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <p>HOME</p>
        </div>
        <p className="desktop">
        <strong>Join the gang,</strong><br/>A limited NFT collection granting you exclusive membership to the AlleyCatGang club. Awesome Art, Merch and much more to come? { price } ETH each!
        </p>
        <img src="/cat-logo.png" className="mobile" alt="" />
        {connected ? (
          <button className="connect-wallet-btn desktop connected-btn" onClick={killSession}>
            <FontAwesomeIcon icon={faWallet} />
            DISCONNECT WALLET
          </button>
        ) : (
          <button className="connect-wallet-btn desktop" onClick={connect}>
            <FontAwesomeIcon icon={faWallet} />
            CONNECT WALLET
          </button>
        )}
        <button
          className={`mobile toggle-btn ${toggleBtn ? "active-toggle" : ""}`}
          onClick={() =>
            setToggleBtn((prev) => {
              if (prev) {
                killSession();
              } else {
                connect();
              }
              return !prev;
            })
          }
        >
          <FontAwesomeIcon icon={faWallet} />
        </button>
      </header>
      <div className="main-mint-page">
        <p className="mobile">
          <strong>Join the gang,</strong><br/>A limited NFT collection granting you exclusive membership to the AlleyCatGang club. Awesome Art, Merch and much more to come? { price } ETH each!
        </p>
        {isMinted && window.innerWidth < 1305 ? (
          <></>
        ) : (
          <div className="left-mint">
            <div className="tab-row">
              <h1>TOO LATE WE ARE SOLD OUT</h1>
            </div>
            <div className="mint-section">
              <img
                src={selected > 0 ? "/select.png" : "/unselect.png"}
                alt=""
              />
              <div className="select-mint">
                <img
                  onClick={() => countNum(1)}
                  className={selected >= 1 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(2)}
                  className={selected >= 2 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(3)}
                  className={selected >= 3 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(4)}
                  className={selected >= 4 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(5)}
                  className={selected >= 5 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(6)}
                  className={selected >= 6 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(7)}
                  className={selected >= 7 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(8)}
                  className={selected >= 8 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(9)}
                  className={selected >= 9 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
                <img
                  onClick={() => countNum(10)}
                  className={selected >= 10 ? "selected" : ""}
                  src="/small.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        )}
        <div className="right-mint">
          <div className="purple-box">
            {isMinted ? (
              <div className="desktop2">
                <img src="/cat-logo.png" alt="" />
                <p className="congrats">
                  <strong>Congragulation</strong> <br /> on your
                </p>
                <h1 className="minted">
                  { balance } <span>MINT/S</span>
                </h1>
                <h3 className="spread">Spread the word</h3>
                <img className="twitter" src="/twitter.png" alt="" onClick={() => window.open("https://twitter.com/theAlleycatgang", "_blank")} />
              </div>
            ) : (
              <>
                <img src="/cat-logo.png" alt="" />
                <h1>TOO LATE WE ARE SOLD OUT</h1>
                <div className="calc-div">
                  <h2 className="desktop">
                    Amount <br /> <span>max 10</span>
                  </h2>
                  <button onClick={() => countDown(selected - 1)}>-</button>
                  <h3>
                    {selected <= 9 ? `0${selected}` : selected} <br />{" "}
                    <span className="mobile">max 10</span>
                  </h3>
                  <button onClick={() => countUp(selected + 1)}>+</button>
                  <button
                    onClick={() => countNum(10)}
                    className="max desktop"
                  >
                    MAX
                  </button>
                </div>
                <div className="total-div">
                  <p>TOTAL</p>
                  <h2>
                    {totalPrice.toFixed(2)}<span style={{ marginLeft: "5px" }}>ETH</span>
                  </h2>
                </div>
                <p className="desktop">
                  REMAINING <br /> <span>0 / {maxCount}</span>
                </p>
                {connected ? (
                  <button className="connect">
                    SOLD OUT
                  </button>
                ) : (
                  <button className="connect" onClick={connect}>
                    <FontAwesomeIcon icon={faWallet} />
                    CONNECT WALLET
                  </button>
                )}
              </>
            )}
          </div>
          {isMinted ? (
            <div className="mobile congrats-mob">
              <p>
                <strong>Congragulation</strong> <br /> on your
              </p>
              <div className="mint-box-mobile">
                <h1>
                  { balance } <span>MINT/S</span>
                </h1>
              </div>
              <img src="/date.png" alt="" />
              <h3 className="spread">Spread the word</h3>
              <img className="twitter" src="/twitter.png" alt="" onClick={() => window.open("https://twitter.com/theAlleycatgang", "_blank")} />
            </div>
          ) : (
            <div className="countdown-div">
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
